export const API_DOMAIN = process.env.NEXT_PUBLIC_API_DOMAIN;
export const API_DOMAIN_ROMA = process.env.NEXT_PUBLIC_API_DOMAIN_ROMA;
export const API_DOMAIN_ZAMP_INVEST = process.env.NEXT_PUBLIC_API_DOMAIN_ZAMP_INVEST;
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;
export const APP_PROTOCOL = process.env.NEXT_PUBLIC_PROTOCOL ?? 'https';
export const ROMA_HOST = process.env.NEXT_PUBLIC_ROMA_HOST ?? '.roma.global';
export const ZAMP_INVEST_HOST = process.env.NEXT_PUBLIC_ZAMP_INVEST_HOST ?? '.zampinvest.com';

export const REQUEST_TIMEOUT = 40000;

export const MIX_PANEL_TEST = process.env.NEXT_PUBLIC_MIXPANEL_IS_DEBUG ? true : false;
export const MIX_PANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '';

export const RUTTER_PUBLIC_KEY = process.env.NEXT_PUBLIC_RUTTER_KEY || '';

export const ASSET_PREFIX = process.env.NEXT_PUBLIC_ASSET_PREFIX ?? '';

export const PLATFORM_HEADER_KEY = 'X-Platform';
export const CSRF_TOKEN_KEY = 'X-ZAMP-CSRF';

export const PLATFORM_ONE_DASHBOARD_VALUE = 'one_dashboard';
export const PLATFORM_IDEMPOTENCY_HEADER_KEY = 'X-ZAMP-Idempotency-Key';

export const ROMA_SHEDULE_A_CALL_URL = 'https://calendly.com/raghav-saraf/30min';
export const YIELD_SHEDULE_A_CALL_URL = 'https://calendly.com/d/zxn-9xs-3q3/zamp-30-minute-meeting?month=2023-03';

export const MFA_TOTP_KEY = 'X-ZAMP-Totp';
export const ACCESS_HEADER_RESOURCE_KEY = 'X-ZAMP-Requested-Resource-Type';
export const ACCESS_HEADER_RESOURCE_ID_KEY = 'X-ZAMP-Requested-Resource-Id';

export const CAPABILITY_INTENT = {
  PAYOUTS: 'payouts',
  TREASURY: 'treasury',
  BIZ_BANKING: 'business_banking',
};

export const BULK_PAYOUT_TEMPLETE =
  'https://storage.googleapis.com/zamp-prd-sg-payments-sdk/assets/Zamp-BulkTransfer-Sample_csv.csv';

export const ACCESS_FLAGS = {
  IS_PAYOUTS_ENABLED: 'is_payouts_enabled',
  IS_TREASURY_ENABLED: 'is_treasury_enabled',
  IS_PAYMENTS_ENABLED: 'is_payments_enabled',
  IS_BUSINESS_BANKING_ENABLED: 'is_business_banking_enabled',
  IS_OTC_ENABLED: 'is_otc_enabled',
};

export const CAPABILITY_WIRE_TRANSFER = 'post_wire_deposit';
export const SENTRY_TAG_PLATFORM = 'PLATFORM';

export const REFERRAL_CODE = 'ref_code';
export const REDIRECT = 'redirect';
export const CODE = 'code';
export const ID = 'id';
export const TYPE = 'type';
