import { APITags } from 'constants/index';
import baseApi from 'services/api';
import { CurrencyAndNetworkConfigs, NavigationBarConfigSchema } from 'types/config';
import { ConfigurationsResponse } from 'types/configurationApi.types';

const Config = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencyAndNetworkConfigs: builder.query<CurrencyAndNetworkConfigs, void>({
      query: () => ({
        url: 'b2b-transfer/v1/currency-configs',
      }),
      transformResponse: ({ data }) => data,
    }),
    getNavigationBarConfig: builder.query<NavigationBarConfigSchema, void>({
      query: () => ({
        url: `v1/dashboard-configs/navigation`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_NAVIGATION],
    }),
    getConfiguration: builder.query<ConfigurationsResponse, void>({
      query: () => ({
        url: `v1/configuration`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getNavigationBarConfigV2: builder.query<NavigationBarConfigSchema[], void>({
      query: () => ({
        url: `v2/dashboard-configs/navigation`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_NAVIGATION],
    }),
  }),
});

export const {
  useGetCurrencyAndNetworkConfigsQuery,
  useLazyGetNavigationBarConfigQuery,
  useLazyGetConfigurationQuery,
  useLazyGetNavigationBarConfigV2Query,
} = Config;
