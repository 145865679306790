import { useState } from 'react';
import { useAddEntityMutation } from 'api/entities';
import { useLazyGetMerchantDetailsQuery } from 'api/home';
import { useEnableDefaultOtcCorridorsMutation } from 'api/moveMoney';
import { useGetProductsQuery } from 'api/prompt';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { CheckBox } from 'destiny/dist/components/atoms/checkbox';
import { Text } from 'destiny/dist/components/atoms/text';
import { Button } from 'destiny/dist/components/molecules/button';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import Input from 'destiny/dist/components/organisms/input';
import { defaultFn } from 'destiny/dist/constants';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { defaultFnType } from 'destiny/dist/types';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { useSessionToken } from 'hooks/useSessionToken';
import {
  MONTHLY_TRADE_VOLUMES,
  OFF_RAMP_CURRENCIES,
  ON_RAMP_CURRENCIES,
  PRODUCT_TYPES_LIST,
} from 'modules/intent/intent.constants';
import { OnOffRampConfig } from 'modules/intent/intent.types';
import { checkDependentProducts, getProductDetails } from 'modules/intent/intent.utils';
import OnOffRampConfigSelector from 'modules/intent/OnOffRampConfigSelector';
import { MapAny, PRODUCT_TYPES, PRODUCT_TYPES_NEW, PRODUCT_TYPES_OLD } from 'types';
import { getVariation } from 'utils/launchDarkly';
import { trackMixpanel } from 'utils/mixpanel';
import CountryPicker from 'components/country-picker/CountryPicker';
import { UNSANCTIONED_COUNTRY_OPTIONS } from 'components/country-picker/unsanctionedCountries';
import SideDrawer from 'components/SideDrawer';

interface AddEntityPopupProps {
  isOpen: boolean;
  onClose: defaultFnType;
  refreshMerchant?: boolean;
}

interface StateType {
  name?: string;
  country?: OptionsType;
  intent?: string[];
}

const AddEntityPopup: React.FC<AddEntityPopupProps> = ({ isOpen, onClose, refreshMerchant = false }) => {
  const [state, setState] = useState<StateType>({ intent: [] });
  const [onRampConfig, setOnRampConfig] = useState<OnOffRampConfig>({
    currencies: ON_RAMP_CURRENCIES,
    monthlyTradeVolumes: MONTHLY_TRADE_VOLUMES[0],
  });
  const [offRampConfig, setOffRampConfig] = useState<OnOffRampConfig>({
    currencies: OFF_RAMP_CURRENCIES,
    monthlyTradeVolumes: MONTHLY_TRADE_VOLUMES[0],
  });
  const [isOnRampSelected, setIsOnRampSelected] = useState(true);
  const [isOffRampSelected, setIsOffRampSelected] = useState(true);
  const [addEntity, { isLoading, error }] = useAddEntityMutation();
  const [enableDefaultOtcCorridors, { isLoading: isEnableDefaultOtcCorridorsLoading }] =
    useEnableDefaultOtcCorridorsMutation();
  const { data } = useGetProductsQuery();
  const { sessionToken, createSessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.CREATE_ENTITY);
  const [getMerchantDetails] = useLazyGetMerchantDetailsQuery();
  const selectedCountryMetadata = state?.country?.metadata;

  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);
  const offeredProducts = isOtcEnabled
    ? Object.keys(data?.offered_products ?? {})
        .filter((objKey) => PRODUCT_TYPES_NEW.includes(objKey as PRODUCT_TYPES))
        .reduce((newObj: any, key: string) => {
          newObj[key] = data?.offered_products[key as PRODUCT_TYPES];

          return newObj;
        }, {})
    : data?.offered_products;

  const productsToShow = isOtcEnabled ? PRODUCT_TYPES_NEW : PRODUCT_TYPES_OLD;

  const handleCountryChange = (items: OptionsType[]) => {
    const item = items?.[0];

    setState((current) => ({ ...current, country: item, intent: data?.country_products_map?.[item?.value] ?? [] }));
  };

  const handleAddEntity = () => {
    const isOtcSelected = state.intent?.includes(PRODUCT_TYPES.OTC_DESK);

    state?.country &&
      state?.name &&
      state?.intent &&
      sessionToken &&
      addEntity({
        data: {
          geography: selectedCountryMetadata?.code?.toString(),
          name: state?.name,
          product_intent: state.intent,
          off_ramp_config: isOffRampSelected
            ? {
                currencies: JSON.stringify(offRampConfig.currencies ?? []),
                monthly_trade_volumes: `${offRampConfig.monthlyTradeVolumes?.value}`,
              }
            : undefined,
          on_ramp_config: isOnRampSelected
            ? {
                currencies: JSON.stringify(onRampConfig.currencies ?? []),
                monthly_trade_volumes: `${onRampConfig.monthlyTradeVolumes?.value}`,
              }
            : undefined,
        },
        idempotencyHeader: sessionToken,
      })
        .then((data: MapAny) => {
          if (!data?.error) {
            handleClose?.();
            refreshMerchant && getMerchantDetails?.();
            console.log({ isOtcSelected });
            isOtcSelected &&
              enableDefaultOtcCorridors()
                .unwrap()
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });
          }
        })
        .finally(createSessionToken);
  };

  const addProductToIntent = (product: PRODUCT_TYPES) => {
    const updatedState = { ...state };
    const productsToAdd = [product, ...(getProductDetails(product, offeredProducts)?.depends_on ?? [])];

    const countryValue = [...(updatedState.intent ?? [])];

    productsToAdd.forEach((product) => {
      if (!countryValue.includes(product)) {
        countryValue.push(product);
      }
    });

    updatedState.intent = countryValue;

    setState(updatedState);
  };

  const removeProductFromIntent = (product: PRODUCT_TYPES) => {
    const updatedState = { ...state };
    const countryValue = [...(state.intent ?? [])];

    const productsToSplice = [product, ...(checkDependentProducts(product, offeredProducts) ?? [])];

    productsToSplice.forEach((product) => {
      if (countryValue.includes(product)) {
        countryValue.splice(countryValue.indexOf(product), 1);
      }
    });

    updatedState.intent = countryValue;

    setState(updatedState);
  };

  const handleProductClick = (product: PRODUCT_TYPES, checked: boolean) => {
    if (checked) {
      removeProductFromIntent(product);
    } else {
      addProductToIntent(product);
    }
  };

  const handleIntentClick = (product: PRODUCT_TYPES) =>
    selectedCountryMetadata?.code &&
    data?.country_products_map?.[selectedCountryMetadata?.code]?.includes(product) &&
    handleProductClick(product, state?.intent?.includes(product) ?? false);

  const handleClose = () => {
    setState({});
    onClose?.();
  };

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={handleClose}
      hideCloseButton
      bodyClassName='tw-w-sideDrawer tw-overflow-auto tw-h-screen tw-bg-white tw-rounded-l-xl'
    >
      <div>
        <div className='tw-h-16 tw-px-8 tw-py-3 tw-flex tw-items-center tw-justify-between' onClick={handleClose}>
          <span className='f-18-500'>Add company</span>
          <SvgSpriteLoader
            id='x-close'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={16}
            height={16}
            className='tw-p-1 tw-rounded-full tw-bg-GRAY_2 tw-cursor-pointer'
          />
        </div>
        <div className='tw-px-8 tw-w-full'>
          <Input
            inputFieldProps={{
              inputTagProps: {
                onChange: (e) => setState((current) => ({ ...current, name: e.target.value })),
                value: state?.name,
                id: 'COMPANY_DETAILS_ADD_ENTITY_NAME',
              },
              size: 'MEDIUM',
            }}
            labelProps={{
              title: 'Business name',
            }}
            showLabel
          />
          <CountryPicker
            label='Business registration country'
            showDialCode={false}
            onMenuItemClick={handleCountryChange}
            id='COUNTRY_DETAILS_ADD_COUNTRY_DROPDOWN'
            selectedValueKey={'name'}
            overrideClassName='tw-w-full tw-my-4'
            labelClassName='tw-text-TEXT_PRIMARY f-14-500'
            options={UNSANCTIONED_COUNTRY_OPTIONS}
            customMenuStyles={{ width: '100%' }}
          />
          {selectedCountryMetadata?.code && (
            <>
              {!data?.country_products_map?.[selectedCountryMetadata?.code ?? 'US']?.length ? (
                <div className='f-12-300 tw-text-TEXT_SECONDARY'>
                  We are yet to make our service available at this location
                </div>
              ) : (
                <>
                  <span className='tw-text-TEXT_PRIMARY f-14-500'>Available services</span>

                  <div
                    className={`tw-flex tw-flex-wrap f-10-600 tw-text-TEXT_SECONDARY tw-mb-1 tw-w-full tw-justify-start -tw-ml-4 tw-gap-3`}
                  >
                    {productsToShow?.map((eachProduct) => {
                      const productDisabled =
                        !data?.country_products_map?.[selectedCountryMetadata?.code ?? 'US']?.includes(
                          eachProduct as PRODUCT_TYPES
                        ) || false;

                      // const productDetails = offeredProducts?.[eachProduct as PRODUCT_TYPES];
                      const productDisplayName =
                        offeredProducts?.[eachProduct.toLowerCase() as PRODUCT_TYPES]?.display_name?.toLowerCase() ??
                        '';

                      if (isOtcEnabled && productDisabled) return null;
                      const productDescription =
                        PRODUCT_TYPES_LIST.find((item) => item.value === eachProduct)?.description ?? '';

                      return (
                        <Tooltip
                          color={COLORS.TEXT_PRIMARY}
                          key={eachProduct}
                          position={TooltipPositions.TOP}
                          disabled={!productDescription}
                          tooltipBody={<Text textClass='f-12-300 tw-text-white'>{productDescription}</Text>}
                          tooltipBodyClassName='tw-py-1 tw-px-2 tw-rounded-[5px]'
                        >
                          <div
                            className={`tw-text-center tw-flex tw-items-center f-14-300 tw-whitespace-nowrap ${
                              productDisabled
                                ? 'tw-text-TEXT_SECONDARY tw-cursor-not-allowed'
                                : 'tw-text-TEXT_PRIMARY tw-cursor-pointer'
                            }`}
                            key={eachProduct}
                            onClick={() => handleIntentClick?.(eachProduct as PRODUCT_TYPES)}
                          >
                            <CheckBox
                              disabled={productDisabled}
                              checked={state?.intent?.includes(eachProduct) ?? false}
                              id={`ADD_COUNTRY_INTENT_CHECKBOX_${eachProduct}`.toUpperCase()}
                              eventCallback={trackMixpanel}
                              onPress={defaultFn}
                            />
                            {productDisplayName?.toUpperCase()}
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                  {state?.intent?.includes(PRODUCT_TYPES.USD_BANKING) && (
                    <div className='tw-space-y-4'>
                      <div className='f-14-400 tw-text-TEXT_SECONDARY tw-mb-4 tw-mt-8'>Select currencies of trade</div>
                      <OnOffRampConfigSelector
                        id='ON'
                        title='For On-ramp'
                        currencies={ON_RAMP_CURRENCIES}
                        config={onRampConfig}
                        setConfig={setOnRampConfig}
                        isSelected={isOnRampSelected}
                        setIsSelected={setIsOnRampSelected}
                      />
                      <OnOffRampConfigSelector
                        id='OFF'
                        title='For Off-ramp'
                        currencies={OFF_RAMP_CURRENCIES}
                        config={offRampConfig}
                        setConfig={setOffRampConfig}
                        isSelected={isOffRampSelected}
                        setIsSelected={setIsOffRampSelected}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {error && (
            <Label
              description={error?.data?.error?.message}
              descriptionClass='tw-text-xs tw-text-RED_SECONDARY tw-py-4'
            />
          )}
          <div className='tw-flex tw-justify-end tw-gap-2 tw-py-3'>
            <Button
              buttonProps={{
                btnType: BUTTON_TYPES.SECONDARY,
                size: BUTTON_SIZE_TYPES.MEDIUM,
                id: 'COMPANY_DETAILS_ADD_ENTITY_CLOSE',
                eventCallback: trackMixpanel,
                onClick: handleClose,
                disabled: isLoading || isEnableDefaultOtcCorridorsLoading,
              }}
            >
              Cancel
            </Button>
            <Button
              buttonProps={{
                btnType: BUTTON_TYPES.PRIMARY,
                size: BUTTON_SIZE_TYPES.MEDIUM,
                id: 'COMPANY_DETAILS_ADD_ENTITY_CONFIRM',
                eventCallback: trackMixpanel,
                onClick: handleAddEntity,
                disabled: !selectedCountryMetadata || !state?.name || !state?.intent?.length,
                isLoading: isLoading || isEnableDefaultOtcCorridorsLoading,
                wrapperClass: 'tw-overflow-clip',
              }}
            >
              Add company
            </Button>
          </div>
        </div>
      </div>
    </SideDrawer>
  );
};

export default AddEntityPopup;
