import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import {
  ACCESS_HEADER_RESOURCE_ID_KEY,
  ACCESS_HEADER_RESOURCE_KEY,
  MFA_TOTP_KEY,
  PLATFORM_IDEMPOTENCY_HEADER_KEY,
} from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { CorridorType } from 'types';
import { VaultsForPayoutsResponseType } from 'types/accountsApi.types';
import {
  GetAccountBalanceForAccountIdRequest,
  GetAccountBalanceForAccountIdResponse,
  GetCurrencyConfigsforBeneficiaryPayload,
  GetVaultsForPayoutsRequestType,
  PayoutConfigsForThirdPartyRequest,
  UpdateSlippagePayload,
  VaultResponse,
} from 'types/api';
import { Map } from 'types/kyb';
import {
  CryptoRFQType,
  CurrencyConfigForBeneficiary,
  FiatPayoutType,
  FiatRFQRequestType,
  FiatRFQType,
  RfqData,
  RfqPayload,
} from 'types/transactions';
import { accessBasedQueryWrapper } from 'utils/access';

const MoveMoney = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorridors: builder.query<Array<CorridorType>, void>({
      query: () => ({
        url: `b2b-transfer/v1/corridors`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getSourceCurrencies: builder.query<Array<CorridorType>, Map>({
      query: (params) => ({
        url: `b2b-transfer/v1/corridors/source-currencies`,
        params,
      }),
      transformResponse: ({ data }) => data,
    }),
    createQuoteCrypto: builder.mutation<CryptoRFQType, Map>({
      query: (payload) => ({
        url: `b2b-transfer/v1/rfq`,
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    createQuoteFiat: builder.mutation<FiatRFQType, FiatRFQRequestType>({
      query: (payload) => ({
        url: `b2b-transfer/v1/rfq-fiat`,
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    getQuoteFiat: builder.query<FiatRFQType, Map>({
      query: ({ quoteId }) => ({
        url: `b2b-transfer/v1/rfq-fiat/${quoteId}`,
      }),
      transformResponse: ({ data }) => data,
    }),
    initiateFiatPayout: builder.mutation<Map, { data: FiatPayoutType; headerKey: string }>({
      query: ({ data, headerKey }) => ({
        url: `b2b-transfer/v1/fiat-payouts`,
        method: REQUEST_TYPES.POST,
        body: data,
        headers: {
          [MFA_TOTP_KEY]: headerKey,
        },
      }),
    }),
    getCurrencyConfigsforBeneficiary: builder.query<
      CurrencyConfigForBeneficiary[],
      GetCurrencyConfigsforBeneficiaryPayload
    >({
      query: ({ beneficiary_id }) => ({
        url: `b2b-transfer/v1/payout-configs/${beneficiary_id}`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getPayoutConfigsForThirdParty: builder.query<CurrencyConfigForBeneficiary[], PayoutConfigsForThirdPartyRequest>({
      query: ({ version, beneficiary_id, vault_id, account_id }) => ({
        url: `b2b-transfer/${version}/payout-configs`,
        params: {
          beneficiary_id,
          vault_id,
          account_id,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
    getAccountBalanceForAccountId: builder.query<
      GetAccountBalanceForAccountIdResponse,
      GetAccountBalanceForAccountIdRequest
    >({
      query: ({ account_id }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_ACCOUNT_DETAILS.permissionId, {
          url: `b2b-transfer/v1/account/balance/${account_id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_ACCOUNT_DETAILS.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: account_id,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    createRfq: builder.mutation<RfqData, RfqPayload>({
      query: (payload) => ({
        url: `b2b-transfer/v1/payout-rfq`,
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    getVaultsForPayoutsV1: builder.query<VaultResponse[], GetVaultsForPayoutsRequestType>({
      query: ({ recipientId }) => ({
        url: `vaults/payout/${recipientId}`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getVaultsForPayouts: builder.query<VaultsForPayoutsResponseType, GetVaultsForPayoutsRequestType>({
      query: ({ recipientId }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: `v2/vaults/payout/${recipientId}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    makePayoutRequest: builder.mutation<any, { data: any; totp: string; sessionToken: string; accountId: string }>({
      query: ({ data, totp, sessionToken, accountId }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.REQUEST_PAYOUT.permissionId, {
          url: `v2/payouts/request`,
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [MFA_TOTP_KEY]: totp,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: sessionToken,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.REQUEST_PAYOUT.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: accountId,
          },
        }),
      invalidatesTags: [APITags.GET_PENDING_TXN_COUNT, APITags.GET_TRANSACTIONS, APITags.GET_TRANSACTIONS_V2],
    }),
    getSlippage: builder.query<number, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: '/b2b-transfer/v1/slippage',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    updateSlippage: builder.mutation<void, UpdateSlippagePayload>({
      query: ({ idempotencyHeader, data }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: '/b2b-transfer/v1/slippage',
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        }),
    }),
    enableDefaultOtcCorridors: builder.mutation<void, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId, {
          url: '/b2b-transfer/v1/otc-corridors/default',
          method: REQUEST_TYPES.POST,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope,
          },
        }),
    }),
  }),
});

export const {
  useGetCorridorsQuery,
  useGetSourceCurrenciesQuery,
  useGetQuoteFiatQuery,
  useCreateQuoteCryptoMutation,
  useCreateQuoteFiatMutation,
  useInitiateFiatPayoutMutation,
  useGetCurrencyConfigsforBeneficiaryQuery,
  useGetPayoutConfigsForThirdPartyQuery,
  useLazyGetAccountBalanceForAccountIdQuery,
  useCreateRfqMutation,
  useLazyGetVaultsForPayoutsV1Query,
  useLazyGetVaultsForPayoutsQuery,
  useGetVaultsForPayoutsQuery,
  useMakePayoutRequestMutation,
  useGetSlippageQuery,
  useUpdateSlippageMutation,
  useEnableDefaultOtcCorridorsMutation,
} = MoveMoney;
