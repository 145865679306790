import { ReactElement, ReactNode, RefObject } from 'react';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { NextPage } from 'next';
import { ACCESS_SCOPES, PERMISSION_NAMES } from 'types/access';
import { WalletAccountDetails } from 'types/romaAccounts';
import { CORRIDOR_TYPE } from 'types/transactions';

export type defaultFnType = () => void;

export type MapString = Record<string, string>;

export type MapAny = Record<string, any>;

export interface RouteType {
  key: string;
  label: string;
  route: string;
  icon: string;
  width?: string | number;
  isSuperUser?: boolean;
  accessFlags?: Array<string>;
  isNew?: boolean;
}

export interface MenuItem {
  label: string;
  value: string | number;
  key?: string;
  color?: string;
  icons?: string;
  isDisabled?: boolean;
  id?: string;
  code?: string;
  index?: number;
  iconId?: string;
  iconCategory?: ICON_SPRITE_TYPES;
  permissionId?: PERMISSION_NAMES;
  scope?: ACCESS_SCOPES;
  customIcon?: ReactElement;
}

export enum ChannelTypes {
  SINGLE_CHANNEL = 'SingleChannel',
  MULTI_CHANNEL = 'MultiChannel',
  CRYPTO_CRYPTO = 'CRYPTO_CRYPTO',
}

export interface CorridorType {
  dest_currency_code: string;
  dest_currency_display_name: string;
  id: string;
  source_currency_code: string;
  source_currency_display_name: string;
  type: CORRIDOR_TYPE;
  source_currency_routing_value: string;
  source_currency_routing_type: string;
  source_currency_min_value: number;
  source_currency_max_value: number;
  dest_currency_min_value: number;
  dest_currency_max_value: number;
  corridor_id: string;
  dest_currency_routing_type: string;
  dest_currency_routing_value: string;
  source_currency_min_value_otc: number;
  source_currency_max_value_otc: number;
  dest_currency_min_value_otc: number;
  dest_currency_max_value_otc: number;
}

export interface WalletDetailsType {
  AvailableBalance: number;
  CurrencyCode: string;
  TotalBalance: number;
  id: number;
}

export enum QUESTION_RESPONSE_TYPE {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  TEXT = 'text',
}

export interface CountryPickerItem {
  name: string;
  dial_code: string;
  code: string;
  emoji: string;
}

export type BalanceCardProps = {
  wallet: WalletAccountDetails;
  index: number;
  onClickAccount?: (index: number) => void;
  parentRef: RefObject<HTMLDivElement>;
};

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export interface CommonPageLayoutProps {
  scrollToTop?: defaultFnType;
  scrollToBottom?: defaultFnType;
  rootContainerRef?: RefObject<HTMLDivElement>;
}

export type NextPageWithLayoutAndProps = NextPageWithLayout<CommonPageLayoutProps>;

export type LABEL_VALUE_API_RESPONSE_TYPE = {
  label: string;
  value: string;
  type?: string;
  action?: string;
  icon_id?: string;
  icon_category?: ICON_SPRITE_TYPES;
};

export enum PRODUCT_TYPES {
  treasury_account = 'treasury_account',
  banking_account = 'banking_account',
  cards = 'cards',
  USD_BANKING = 'usd_banking',
  CRYPTO_PAYMENT_GATEWAY = 'crypto_payment_gateway',
  OTC_DESK = 'otc_desk',
}

export const PRODUCT_TYPES_OLD = [PRODUCT_TYPES.treasury_account, PRODUCT_TYPES.banking_account, PRODUCT_TYPES.cards];

export const PRODUCT_TYPES_NEW = [
  PRODUCT_TYPES.USD_BANKING,
  PRODUCT_TYPES.CRYPTO_PAYMENT_GATEWAY,
  PRODUCT_TYPES.OTC_DESK,
];

export enum PRODUCT_GROUPS {
  banking = 'banking',
  treasury = 'treasury',
  cards = 'cards',
  USD_BANKING = 'usd_banking',
  CRYPTO_PAYMENT_GATEWAY = 'crypto_payment_gateway',
  OTC_DESK = 'otc_desk',
}

export interface EachOfferedProductType {
  id: string;
  display_name: string;
  description: string;
  onboarding_time: string;
  depends_on: PRODUCT_TYPES[];
}

export interface OfferedProductsType {
  [PRODUCT_TYPES.banking_account]: EachOfferedProductType;
  [PRODUCT_TYPES.cards]: EachOfferedProductType;
  [PRODUCT_TYPES.treasury_account]: EachOfferedProductType;
  [PRODUCT_TYPES.USD_BANKING]: EachOfferedProductType;
  [PRODUCT_TYPES.CRYPTO_PAYMENT_GATEWAY]: EachOfferedProductType;
  [PRODUCT_TYPES.OTC_DESK]: EachOfferedProductType;
}

export enum ACTIVE_IF_COMPARATOR_TYPES {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  INCLUDES = 'in',
}

export interface UploadFileResponseType {
  identifier: string;
  url: string;
  fileName: string;
  downloadableUrl: string;
  rawFile: File | null;
}

export enum SIZE_TYPES {
  XSMALL,
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
}
