import React, { FocusEventHandler, useRef } from 'react';
import { ORIENTATION_ENUMS } from 'constants/index';
import { Dropdown } from 'destiny/dist/components/organisms/dropdown';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { CountryPickerItem } from 'types';
import { trackMixpanel } from 'utils/mixpanel';
import { COUNTRY_OPTIONS } from 'components/country-picker/countries';
import FormToolTip from 'components/tooltip/FormToolTip';

interface DropdownProps {
  id: string;
  options?: Array<CountryPickerItem>;
  selectedItem?: CountryPickerItem | undefined | null;
  onMenuItemClick: (menu: OptionsType[], index: number) => void;
  onBlur?: FocusEventHandler;
  type?: string;
  className?: string;
  overrideClassName?: string;
  error?: string;
  isSearchable?: boolean;
  onSearch?: any;
  label?: string;
  labelClassName?: string;
  orientation?: ORIENTATION_ENUMS;
  selectedValueKey: keyof CountryPickerItem;
  showDialCode?: boolean;
  placeholder?: string;
  multiple?: boolean;
  defaultValue?: OptionsType | OptionsType[];
  value?: OptionsType | OptionsType[];
  isControlled?: boolean;
  customControlStyles?: React.CSSProperties;
  hideSupporterInfo?: boolean;
  isDisabled?: boolean;
  isFormComponent?: boolean;
  customMenuStyles?: React.CSSProperties;
}

const CountryPicker: React.FC<DropdownProps> = ({
  className = '',
  overrideClassName = '',
  id,
  options = COUNTRY_OPTIONS,
  error,
  onMenuItemClick,
  label,
  orientation = ORIENTATION_ENUMS.HORIZONTAL,
  selectedValueKey = 'dial_code',
  showDialCode = true,
  placeholder = 'Select',
  multiple = false,
  defaultValue,
  isControlled = false,
  value,
  customControlStyles = {
    padding: '0px 24px',
    minHeight: '52px',
    height: '52px',
  },
  hideSupporterInfo = false,
  labelClassName = 'f-14-500',
  isDisabled = false,
  isFormComponent = false,
  customMenuStyles = {},
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleChange = (menuItem: OptionsType | OptionsType[]) => {
    if (Array.isArray(menuItem)) {
      onMenuItemClick(menuItem, 0);
    } else {
      onMenuItemClick([menuItem], 0);
    }
  };

  return (
    <div role='presentation' className={overrideClassName || `tw-w-[116px]`}>
      <div className={`${className}`} ref={ref}>
        {isFormComponent && <FormToolTip label={label} labelClassName={labelClassName} />}
        <Dropdown
          countSelectedSuffix='Selected'
          isMulti={multiple}
          labelProps={{
            showTitle: !isFormComponent,
            title: label,
            titleClass: `tw-mb-3 tw-text-TEXT_PRIMARY ${labelClassName}`,
          }}
          noOptionsText='No options'
          showCountOfSelected
          controlled={isControlled}
          defaultValue={defaultValue}
          value={value}
          showLabel
          showSupporterInfo={!!error}
          error={!!error}
          placeholder={placeholder}
          selectFieldWrapperClass='tw-w-full tw-my-0'
          onChange={handleChange}
          options={options?.map((each) => ({
            label: `${each.name}${showDialCode ? ' (' + each.dial_code + ')' : ''}`,
            value: each[selectedValueKey],
            icon: <span className='tw-mr-2'>{each.emoji}</span>,
            metadata: each,
          }))}
          customStyles={{
            control: customControlStyles,
            valueContainer: {
              minHeight: 'unset',
            },
            menu: {
              width: multiple ? '280px' : '240px',
              zIndex: '100',
              right: orientation === ORIENTATION_ENUMS.HORIZONTAL_REVERSE ? '0' : 'unset',
              overflow: 'hidden',
              ...customMenuStyles,
            },
          }}
          id={`${id?.toUpperCase()}_COUNTRY_DROPDOWN`}
          eventCallback={trackMixpanel}
          showValueInControl
          isDisabled={isDisabled}
        />
      </div>
      {!!error && !hideSupporterInfo && <div className={`tw-text-RED_PRIMARY tw-mt-3 f-12-300`}>{error}</div>}
    </div>
  );
};

export default CountryPicker;
